import React from 'react';

import './ForestResearchLogo.css';

const ForestResearchLogo = (props) => {

    return (
        <div className='fr-logo-container'>
            <svg className='fr-logo'>
                <title>Forest Research logo</title>
                <path className="svg-conifer" d="M25.2 38.5l1-2.3 5.2-4.2-.8-4.2-1.1-4.3 4.2-8.3h-1.7l7-15.2 6.7 15.4h-1.7l5.9 12.7h-2.2l4.3 10.4h-10.8v10.2h-5.1v-10.2"></path>
                <path className="svg-oak-edge" d="M22.8 24.1h.6c3.2 0 5.8 2.6 5.8 5.8 0 3.2-2.6 5.8-5.8 5.8-1.6 0-3-.6-4.1-1.7h-4.3c-.6 1.3-1.9 2.7-3.3 3.2-2.2.9-4.8.2-6.4-1.5-1.6-1.6-1.8-4.1-1.1-6 .6-1.5 2.2-2.8 3.7-3.3l2-.2.2-1.9c-.8 0-1.7-.5-2.1-1.3-.4-.5-.1-1.3.1-1.8.5-.7 1.4-1 2.2-.7.1.1.3.3.4.3l1.5-1.2c-.3-2.5.7-4.6 2.8-5.8 1.3-.7 3.2-1.1 4.7-.5 1.4.4 2.7 1.6 3.4 2.9.8 2 .7 4.4-.6 6.1l-.5.5.8 1.3m4.5-3.4c.7-4.1-.9-8.1-4.4-10.3-2.6-1.5-6.1-1.7-8.9-.5-2.8 1.2-5.1 4.1-5.7 7-1 .3-1.8.7-2.6 1.4-1.3 1.4-1.8 3.5-1.4 5.5-2.9 2-4.3 5.1-4.3 8.4.1 3.9 2.7 7.2 6.3 8.7 2.7 1.1 6.4.6 8.7-.7-.2 2.7-.9 5.2-2.7 7.2h11.3c-1.9-2.2-3.3-4.8-2.7-7.9.6.4 1.8.5 2.8.5 5.4 0 9.7-4.3 9.7-9.7 0-4.1-2.6-7.6-6.2-9l.1-.6z"></path>
                <path className="svg-conifer" d="M25.2 38.5l1-2.3 5.2-4.2-.8-4.2-1.1-4.3 4.2-8.3h-1.7l7-15.2 6.7 15.4h-1.7l5.9 12.7h-2.2l4.3 10.4h-10.8v10.2h-5.1v-10.2"></path>
                <path className="svg-oak" d="M22.8 24.1h.6c3.2 0 5.8 2.6 5.8 5.8 0 3.2-2.6 5.8-5.8 5.8-1.6 0-3-.6-4.1-1.7h-4.3c-.6 1.3-1.9 2.7-3.3 3.2-2.2.9-4.8.2-6.4-1.5-1.6-1.6-1.8-4.1-1.1-6 .6-1.5 2.2-2.8 3.7-3.3l2-.2.2-1.9c-.8 0-1.7-.5-2.1-1.3-.4-.5-.1-1.3.1-1.8.5-.7 1.4-1 2.2-.7.1.1.3.3.4.3l1.5-1.2c-.3-2.5.7-4.6 2.8-5.8 1.3-.7 3.2-1.1 4.7-.5 1.4.4 2.7 1.6 3.4 2.9.8 2 .7 4.4-.6 6.1l-.5.5.8 1.3m4.5-3.4c.7-4.1-.9-8.1-4.4-10.3-2.6-1.5-6.1-1.7-8.9-.5-2.8 1.2-5.1 4.1-5.7 7-1 .3-1.8.7-2.6 1.4-1.3 1.4-1.8 3.5-1.4 5.5-2.9 2-4.3 5.1-4.3 8.4.1 3.9 2.7 7.2 6.3 8.7 2.7 1.1 6.4.6 8.7-.7-.2 2.7-.9 5.2-2.7 7.2h11.3c-1.9-2.2-3.3-4.8-2.7-7.9.6.4 1.8.5 2.8.5 5.4 0 9.7-4.3 9.7-9.7 0-4.1-2.6-7.6-6.2-9l.1-.6z"></path>
                <path className="svg-text" d="M79.3 30.3c0 3.4-2.4 6.4-5.9 6.4-3.6 0-5.9-2.9-5.9-6.4 0-3.4 2.4-6.4 5.9-6.4 3.4 0 5.9 3 5.9 6.4m-8.6 0c0 1.6.8 3.3 2.6 3.3s2.6-1.7 2.6-3.3c0-1.6-.8-3.4-2.6-3.4s-2.6 1.8-2.6 3.4zM80.8 25.7c0-1.1.9-1.7 1.7-1.7.9 0 1.6.3 1.6 1.4.6-.9 1.3-1.4 2.3-1.4.8 0 1.6.6 1.6 1.8 0 1.1-1 1.1-2 1.6s-2 .9-2 2.2v5.1c0 1.1-.7 1.8-1.7 1.8s-1.7-.7-1.7-1.8"></path>
                <path className="svg-text" d="M57.6 21.8c0-.9.6-1.8 1.8-1.8h5.9c1 0 1.5.8 1.5 1.6s-.5 1.6-1.5 1.6h-4.3v3.3h3.6c1.1 0 1.7.8 1.7 1.6s-.6 1.6-1.7 1.6h-3.6v5c0 1.1-.7 1.8-1.7 1.8s-1.7-.6-1.7-1.8"></path>
                <path className="svg-text" d="M91 31.4c.2 1.6 1.6 2.4 3.1 2.4 1.7 0 2.9-1.3 3.7-1.3.7 0 1.4.7 1.4 1.4 0 1.4-2.9 2.8-5.4 2.8-3.7 0-6.2-2.7-6.2-6.4 0-3.4 2.4-6.4 5.9-6.4 3.6 0 5.9 3.3 5.9 6 0 1-.4 1.5-1.4 1.5m-1.8-2.2c-.2-1.4-1.1-2.5-2.6-2.5s-2.5 1.1-2.6 2.5h5.2zM107.9 27.7c-.6 0-1.9-1-3-1-.6 0-1.1.3-1.1.9 0 1.5 5.7 1.3 5.7 5.1 0 2.2-1.9 3.9-4.7 3.9-1.9 0-4.6-1.1-4.6-2.5 0-.5.5-1.5 1.4-1.5 1.3 0 1.9 1.1 3.4 1.1 1 0 1.3-.3 1.3-.9 0-1.5-5.7-1.3-5.7-5.1 0-2.3 1.9-3.9 4.5-3.9 1.6 0 4.1.8 4.1 2.4 0 .8-.6 1.5-1.3 1.5M111.7 27.1h-.7c-.9 0-1.4-.6-1.4-1.4 0-.8.6-1.4 1.4-1.4h.7v-2c0-1.1.7-1.8 1.7-1.8s1.7.7 1.7 1.8v2h.9c.8 0 1.6.4 1.6 1.4 0 1-.7 1.4-1.6 1.4h-1v7.6c0 1.1-.7 1.8-1.7 1.8s-1.7-.7-1.7-1.8"></path>
                <path className="svg-text" d="M124.4 21.8c0-1.1.6-1.8 1.8-1.8h4c3.5 0 5.7 1.6 5.7 5.2 0 2.5-1.9 4-4.2 4.3l3.9 4.2c.3.3.5.7.5 1 0 .9-.7 1.8-1.7 1.8-.4 0-1-.2-1.3-.6l-5.1-6.2v5c0 1.1-.7 1.8-1.7 1.8s-1.7-.6-1.7-1.8m3.2-7.4h2.4c1.3 0 2.1-.8 2.1-2 0-1.3-.9-2-2.1-2h-2.4v4zM140 31.4c.2 1.6 1.6 2.4 3.1 2.4 1.7 0 2.9-1.3 3.7-1.3.7 0 1.4.7 1.4 1.4 0 1.4-2.9 2.8-5.4 2.8-3.7 0-6.2-2.7-6.2-6.4 0-3.4 2.4-6.4 5.9-6.4 3.6 0 5.9 3.3 5.9 6 0 1-.4 1.5-1.4 1.5m-1.9-2.2c-.2-1.4-1.1-2.5-2.6-2.5s-2.5 1.1-2.6 2.5h5.2zM156.8 27.7c-.6 0-1.9-1-3-1-.6 0-1.1.3-1.1.9 0 1.5 5.7 1.3 5.7 5.1 0 2.2-1.9 3.9-4.7 3.9-1.9 0-4.6-1.1-4.6-2.5 0-.5.5-1.5 1.4-1.5 1.3 0 1.9 1.1 3.4 1.1 1 0 1.3-.3 1.3-.9 0-1.5-5.7-1.3-5.7-5.1 0-2.3 1.9-3.9 4.5-3.9 1.6 0 4.1.8 4.1 2.4.1.8-.5 1.5-1.3 1.5"></path>
                <path className="svg-text" d="M162.5 31.4c.2 1.6 1.6 2.4 3.1 2.4 1.7 0 2.9-1.3 3.7-1.3.7 0 1.4.7 1.4 1.4 0 1.4-2.9 2.8-5.4 2.8-3.7 0-6.2-2.7-6.2-6.4 0-3.4 2.4-6.4 5.9-6.4 3.6 0 5.9 3.3 5.9 6 0 1-.4 1.5-1.4 1.5m-1.8-2.2c-.2-1.4-1.1-2.5-2.6-2.5s-2.5 1.1-2.6 2.5h5.2zM183.7 34.3c0 .8 0 2.2-1.6 2.2-1 0-1.4-.5-1.6-1.4-.8 1-1.9 1.5-3.1 1.5-3.1 0-5.5-2.6-5.5-6.4 0-3.6 2.4-6.4 5.5-6.4 1.2 0 2.4.5 3.1 1.5.1-.8.8-1.4 1.6-1.4 1.6 0 1.6 1.3 1.6 2.2m-5.9 7.5c1.7 0 2.5-1.7 2.5-3.3 0-1.6-.8-3.4-2.5-3.4-1.8 0-2.6 1.8-2.6 3.4 0 1.6.9 3.3 2.6 3.3zM185.8 25.7c0-1.1.9-1.7 1.7-1.7.9 0 1.6.3 1.6 1.4.6-.9 1.3-1.4 2.3-1.4.8 0 1.6.6 1.6 1.8 0 1.1-1 1.1-2 1.6s-2 .9-2 2.2v5.1c0 1.1-.7 1.8-1.7 1.8s-1.7-.7-1.7-1.8M201.5 27.6c-.8 0-1.6-.6-2.6-.6-1.9 0-2.8 1.7-2.8 3.4 0 1.6 1 3.3 2.8 3.3.8 0 2.1-.7 2.5-.7.8 0 1.4.7 1.4 1.4 0 1.7-2.8 2.3-4 2.3-3.6 0-5.9-2.9-5.9-6.4 0-3.4 2.4-6.4 5.9-6.4 1.3 0 4 .5 4 2.1-.1.7-.5 1.6-1.3 1.6M204.1 20.5c0-1.1.7-1.8 1.7-1.8s1.7.7 1.7 1.8v4.5c.7-.8 2-1.1 3-1.1 2.6 0 4.5 1.7 4.5 4.5v6.3c0 1.1-.7 1.8-1.7 1.8s-1.7-.7-1.7-1.8v-5.8c0-1.5-1-2.3-2.2-2.3-1.4 0-2.1 1-2.1 2.3v5.8c0 1.1-.7 1.8-1.7 1.8s-1.7-.7-1.7-1.8"></path>
            </svg>
        </div>
    );
}

export default ForestResearchLogo;
